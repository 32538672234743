<template>
  <div>
    <banner-message></banner-message>
    <div class="container px-0">
      <b-row class="justify-content-center">
        <div class="col-md-12 col-12">
          <div class="mb-3">
            <h1 class="mb-4 text-center">Expenses Stats</h1>
          </div>
        </div>
      </b-row>
      <b-col class="col-md-12">
        <div>
          <b-col class="px-0">
            <hr class="mb-4" />
            <div class="text-center mb-3">
              For which year would you like to export the report?
              <div class="tooltip"><i class="fas fa-question-circle"></i><div class="tiptext">Tax filing season always begins in September after the tax year finishes<i></i></div></div>
              <select v-model="form.year">
                <option v-for="year in years" :key="year" :value="`${year-1}-03-01`">
                  {{ year }} (Mar {{ year - 1 }} - Feb {{ year }})
                </option>
              </select>
            </div>
            <b-button
              variant="success"
              class="w-100 py-2 btn-new text-white mb-3 tt-border-radius"
              @click="exportAsPdf"
              >Export expenses</b-button
            >
          </b-col>
        </div>
      </b-col>
      <report
        @fireUpdateChartEvent="updateChart"
        :chartOptions="chartOptions"
        :EmptyChartOptions="EmptyChartOptions"
        :isViewedByMonth="isViewedByMonth"
      >
      </report>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapState } from "vuex";
import Report from "./Report";
import axios from "axios";
import BannerMessage from "../components/partials/BannerMessage";
import download from "downloadjs";

export default {
  name: "Stats",
  components: {
    Report,
    BannerMessage
  },
  data() {
    return {
      newExpenseModalState: "hide",
      expenseTabIsActive: true,
      isViewedByMonth: false,
      form: {
        year: `${new Date().getFullYear()}-03-01`,
      },
      years: Array.from({ length: 2 }, (_, i) => new Date().getFullYear() + i),
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      selectedMonth: new Date(),
      initialMonth: moment(new Date()),
      // recreateReportComp: 0,
      recalculate: 0,
      chartOptions: {
        chart: {
          plotBackgroundColor: "#f5f6f9",
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
          backgroundColor: "#f5f6f9"
        },
        title: {
          text: "Expenses for the year"
        },
        tooltip: {
          pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>"
        },
        accessibility: {
          point: {
            valueSuffix: "%"
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: false
            },
            showInLegend: true
          }
        },
        series: [
          {
            name: "Categories",
            colorByPoint: true,
            data: []
          }
        ],
        credits: {
          enabled: false
        }
      },
      EmptyChartOptions: {
        chart: {
          plotBackgroundColor: "#f5f6f9",
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
          backgroundColor: "#f5f6f9"
        },
        title: {
          text: "Expenses"
        },
        subtitle: {
          text: "No data yet. Start logging expenses to see this graph come alive",
          align: 'center',
          verticalAlign: 'bottom',
          style: {
            fontSize: '14px'
          }
        },
        tooltip: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: false
            },
            showInLegend: false,
            states: {
              hover: {
                enabled: false
              },
              inactive: {
                opacity: 1
              }
            }
          }
        },
        series: [
          {
            name: "Expenses",
            colorByPoint: true,
            enableMouseTracking: false,
            data: [
              {
                name: "Category A",
                y: 50,
                color: "#bebfc2"
              },
              {
                name: "Category B",
                y: 30,
                color: "#b8c1c7"
              },
              {
                name: "Category C",
                y: 20,
                color: "#b2b2b6"
              }
            ]
          }
        ]
      }
    };
  },
  created() {
    setTimeout(() => {
      this.showByYear();
    }, 300);
  },
  methods: {
    //The methods below are all for the report
    updateChart(viewBy, month) {
      this.isViewedByMonth = viewBy === "month";
      if (!this.isViewedByMonth) {
        this.showByYear();
      } else {
        month = this.determineMonth(month) || null;
        this.determineDisplayTextOnChart(month);
        this.chartOptions.series[0].data = this.showByMonth(
          this.expenseForAMonth
        );
      }
    },
    showByYear() {
      setTimeout(() => {
        this.determineDisplayTextOnChart();
        this.chartOptions.series[0].data = this.buildReportDataObjForAYear();
      }, 1000);
    },
    buildReportDataObjForAYear() {
      let reportData = this.allocateSavedExpensesToSelectedCategories(
        this.expenseForASeason
      );
      // console.log(this.expenseForASeason);
      let arr = [];
      for (let b in reportData) {
        arr.push(reportData[b]);
      }
      return arr;
    },
    buildReportDataObjForAMonth: function(val) {
      let reportData = this.allocateSavedExpensesToSelectedCategories(val);
      let arr = [];
      for (let b in reportData) {
        arr.push(reportData[b]);
      }

      return arr;
    },
    determineDisplayTextOnChart: function(month) {
      this.recalculate++;
      this.selectedMonth = this.initialMonth;

      this.chartOptions.title.text =
        month === undefined
          ? "Expenses for the year " + new Date().getFullYear()
          : "Expenses for the month of " + this.monthNames[month.month()];
      return this.chartOptions.title.text;
    },
    showByMonth(val) {
      setTimeout(() => {
        this.chartOptions.series[0].data = this.buildReportDataObjForAMonth(
          val
        );
      }, 1000);
    },
    determineMonth: function(monthDigit) {
      if (monthDigit === 0) {
        this.initialMonth = moment(new Date());
        return moment(new Date());
      }
      if (monthDigit === 1) {
        this.initialMonth = this.initialMonth.add(1, "months");
        return this.initialMonth;
      }

      if (monthDigit === -1) {
        this.initialMonth = moment(this.initialMonth).subtract(1, "months");
        return this.initialMonth;
      }
    },
    allocateSavedExpensesToSelectedCategories: function(expenses) {
      let reportData = {};
      for (let expense of expenses) {
        if (expense.expense_tracker_expense_sub_category &&
          this.selectedCategoriesWithSavedExpenseCounter[
            expense.expense_tracker_expense_sub_category.id
        ]) {
          this.selectedCategoriesWithSavedExpenseCounter[
            expense.expense_tracker_expense_sub_category.id
          ].y++;
          reportData[
            expense.expense_tracker_expense_sub_category.id
          ] = this.selectedCategoriesWithSavedExpenseCounter[
            expense.expense_tracker_expense_sub_category.id
          ];
        }
      }
      return reportData;
    },
    exportAsPdf: function() {
      const vm = this;

      axios
        .post(
          "/expense-tracker-expense/export-pdf",
          {
            taxSeasonStart: this.form.year,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwt")
            },
            responseType: "blob"
          }
        )
        .then(function(response) {
          if (response.data.type === "application/pdf") {
            vm.exportError = false;
            download(response.data, "expenses.pdf", "application/pdf");
          } else {
            vm.exportError = true;
          }
        }) // eslint-disable-next-line no-unused-vars
        .catch(function(error) {});
    }
  },
  computed: {
    ...mapState({
      loggedIn: state => state.authStore.isAuth,
      fail: state => state.expenseStore.fail,
      success: state => state.expenseStore.success
    }),
    ...mapGetters({
      selectedCategoriesWithSavedExpenseCounter:
        "reportStore/getArrOfSelectedCatWithExpenseCounter",
      seasonDateStart: "reportStore/getStartDate",
      allExpenses: "expenseStore/getActiveExpense"
    }),

    expenseForASeason: function() {
      return this.allExpenses.filter(item => {
        return moment(item.purchase_time).isBetween(
          this.seasonDateStart,
          moment(this.seasonDateStart).add(1, "year")
        );
      });
    },

    expenseForAMonth: function() {
      this.recalculate;
      return this.expenseForASeason.filter(item => {
        return (
          moment(new Date(item.purchase_time)).format("MM YY") ===
          moment(new Date(this.selectedMonth)).format("MM YY")
        );
      });
    },
    emailDomain: function() {
      return localStorage.getItem("email") ? localStorage.getItem("email").split("@")[1] : null;
    }
  },
  watch: {
    expenseForASeason: function() {
      this.$route.params;
      setTimeout(() => {
        this.buildReportDataObjForAYear();
      }, 2000);
    },
    selectedMonth: function() {
      this.$store.commit("reportStore/setCurrentDate", this.selectedMonth);
    }
  }
};
</script>

<style scoped></style>

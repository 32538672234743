<template>
  <b-row class="justify-content-center">
    <hr />
    <div class="col-md-12">
      <hr class="mb-1" />
      <div class="form-group h1 text-center">
        <span
          class="toggle-font-size px-2"
          :class="{ 'text-primary': !isViewedByMonth }"
          >Yearly</span
        >
        <div
          class="custom-control custom-switch custom-switch-adaptive d-inline-flex"
        >
          <input
            id="toggle"
            type="checkbox"
            class="custom-control-input"
            :checked="isViewedByMonth"
            @change="switchView"
          />
          <label
            for="toggle"
            class="custom-control-label toggle-font-size py-2 mx-2 px-4"
            >Switch View</label
          >
        </div>
        <span
          class="px-2 toggle-font-size"
          :class="{ 'text-success': isViewedByMonth }"
          >Monthly</span
        >
      </div>
      <div class="row mx-0" id="chart">
        <div class="col pt-5 pl-2 px-0" v-if="isViewedByMonth">
          <b-iconstack @click="updateChartData('month', -1)">
            <b-icon icon="chevron-left" scale="2" variant="primary"></b-icon>
          </b-iconstack>
        </div>
        <div
          :class="{
            'col-md-12': !isViewedByMonth,
            'col-12': !isViewedByMonth,
            'col-md-10': isViewedByMonth,
            'col-10': isViewedByMonth
          }"
        >
          <chart v-show="expenses.length > 0" :options="chartOptions"></chart>
          <chart v-show="expenses.length === 0" :options="EmptyChartOptions"></chart>
        </div>
        <div class="col pt-5 pr-2 px-0 text-right" v-if="isViewedByMonth">
          <b-iconstack @click="updateChartData('month', 1)">
            <b-icon icon="chevron-right" scale="2" variant="primary"></b-icon>
          </b-iconstack>
        </div>
      </div>
    </div>
  </b-row>
</template>

<script>
import { Chart } from "highcharts-vue";
import { mapGetters } from "vuex";
export default {
  name: "Report",
  components: {
    Chart
  },
  data() {
    return {
      selectedMonth: new Date().getMonth(),
      selected: [], // Must be an array reference!
      options: [{ value: "red" }]
    };
  },
  props: {
    chartOptions: {
      type: Object,
      required: true
    },
    EmptyChartOptions: {
      type: Object,
      required: true
    },
    isViewedByMonth: {
      type: Boolean
    }
  },
  methods: {
    updateChartData: function(type, monthDigit) {
      this.$emit("fireUpdateChartEvent", type, monthDigit);
    },
    switchView: function(value) {
      if (value.target.checked) {
        this.updateChartData("month", 0);
      } else {
        this.updateChartData("year");
      }
    }
  },
  computed: {
    ...mapGetters({
      expenses: "expenseStore/getActiveExpense"
    })
  }
};
</script>

<style>
.toggle-font-size {
  font-size: 0.3em;
}

.custom-switch.custom-switch-adaptive .custom-control-label::after {
  top: 0.9em !important;
}
#chart .b-icon.bi {
  vertical-align: -9em;
}
@media (max-width: 380px) {
  .toggle-font-size {
    font-size: 0.27em;
  }
}
</style>
